export const PERMISSION = {
  TRANSACTION_LISTING_AND_POSTING: {
    functionName: 'Transaction Listing & Posting',
  },
  TENANT_REDEMPTION_REPORTS: {
    functionName: 'Tenant Redemption Report',
  },
  USER_TM: {
    functionName: 'User TM',
  },
  TENANT_DETAIL_REPORTS: {
    functionName: 'Tenant Details Report',
  },
  REPORT: {
    functionName: 'Reports',
  },
  TENANT_COMMISSION_REPORTS: {
    functionName: 'Tenant Commission Report',
  },
  TRANSACTION_ADJUSTMENT: {
    functionName: 'Transaction Adjustment',
  },
  USER_PROFILE_TM: {
    functionName: 'User Profile TM',
  },
  SANDS_REWARDS_SUMMARY_REPORTS: {
    functionName: 'Sands Rewards Summary Report',
  },
  TRANSACTION_CANCELLATION: {
    functionName: 'Transaction Cancellation',
  },
  USER_ACCESS_TM: {
    functionName: 'User Access TM',
  },
  SANDS_REWARDS_ADJUSTMENTS_REPORTS: {
    functionName: 'Sands Rewards Adjustment Report',
  },
  STORE_MANAGER: {
    functionName: 'Store Manager',
  },
  CONTENT_MANAGER: {
    functionName: 'Content Manager',
  },
  USER_RETAILER: {
    functionName: 'User Retailer',
  },
  ADMIN_FEE_MAINTENANCE: {
    functionName: 'Admin Fee Maintenance',
  },
  USER_PROFILE_RETAILER: {
    functionName: 'User Profile Retailer',
  },
  CAMPAIGN: {
    functionName: 'Campaign',
  },
  USER_ACCESS_RETAILER: {
    functionName: 'User Access Retailer',
  },
  TICKETS_BY_STATUS_REPORT: {
    functionName: 'Tickets by Status Report',
  },
  MANUAL_TICKET_AUDIT_REPORT: {
    functionName: 'Manual Ticket Audit Report',
  },
  STORE_SEGMENT: {
    functionName: 'Store Segment',
  },
  CAMPAIGN_SUMMARY_REPORT: {
    functionName: 'Campaign Admin Fees Summary Report',
  },
  CAMPAIGN_DETAILS_REPORT: {
    functionName: 'Campaign Admin Fees Detail Report',
  },
  CAMPAIGN_EARNING_SUMMARY_REPORT: {
    functionName: 'Campaign Earning Summary Report',
  },
  CAMPAIGN_EARNING_DETAIL_REPORT: {
    functionName: 'Campaign Earning Detail Report',
  },
  PATRON_SEGMENT: {
    functionName: 'Patron Segment',
  },
  WATCHLIST_MANAGEMENT: {
    functionName: 'Watchlist Management',
  },
  WATCHLIST_SUB: {
    functionName: 'Watchlist Sub',
  },
  AP_SRL_PAYMENT_REPORT: {
    functionName: 'AP SRL Payment Report',
  },
  USER: {
    functionName: 'User',
  },
  TENANTS_BILLING_REPORT: {
    functionName: 'Tenant Billing Report',
  },
  MANUAL_WEB_INVOICE_GENERATION: {
    functionName: 'Manual Web Invoice Generation',
  },
  CATEGORIES_MAINTENANCE: {
    functionName: 'Categories Maintenance',
  },
  GST_MAINTENANCE: {
    functionName: 'GST Rate Maintenance',
  },
  CARDTIER_MAINTENANCE: {
    functionName: 'Card Tier Maintenance',
  },
  CAMPAIGN_APPROVAL: {
    functionName: 'Campaign Approval',
  },
  LEVELS_MAINTENANCE: {
    functionName: 'Levels Maintenance',
  },
  PRIVILEGES: {
    functionName: 'Privileges',
  },
  CHANGE_STORE_PIN: 'Change Store Pin',
  TRANSACTION_REJECT: {
    functionName: 'Transaction Reject',
  },
  TRANSACTION_APPROVE: {
    functionName: 'Transaction Approve',
  },
  CONTACT_MANAGER: {
    functionName: 'Contact Manager',
  },
  STORE_SUB: {
    functionName: '[Store Sub] Payment Details',
  },
  INVOICE_TRACKING: {
    functionName: 'Invoice Tracking',
  },
  TURNKEY_HISTORICAL: {
    functionName: 'Turnkey Historical',
  },
  TURNKEY_HISTORICAL_CAMPAIGN_SUMMARY_REPORT: {
    functionName: 'Turnkey Historical - Campaign Summary Report',
  },
  TURNKEY_HISTORICAL_CAMPAIGN_DETAIL_REPORT: {
    functionName: 'Turnkey Historical - Campaign Detail Report',
  },
  TURNKEY_HISTORICAL_DETAILED_REDEMPTION_REPORT: {
    functionName: 'Turnkey Historical - Detailed Redemption Report',
  },
  TURNKEY_HISTORICAL_INTERNAL_REDEMPTION_REPORT: {
    functionName: 'Turnkey Historical - Internal Redemption Report',
  },
  TURNKEY_HISTORICAL_INTERNAL_REDEMPTION_RASAPURA_REPORT: {
    functionName: 'Turnkey Historical - Internal Redemption Report Rasapura',
  },
  TURNKEY_HISTORICAL_LMP_COMMISSION_REPORT: {
    functionName: 'Turnkey Historical - LMP Commission Report',
  },
  TURNKEY_HISTORICAL_LMP_SUMMARY_REPORT: {
    functionName: 'Turnkey Historical - LMP Summary Report',
  },
  TURNKEY_HISTORICAL_LMP_TREND_REPORT: {
    functionName: 'Turnkey Historical - LMP Trend Report',
  },
  TURNKEY_HISTORICAL_MANUAL_TICKET_AUDIT_REPORT: {
    functionName: 'Turnkey Historical - Manual Ticket Audit Report',
  },
  TURNKEY_HISTORICAL_REDEMPTION_RECONCILIATION_REPORT: {
    functionName: 'Turnkey Historical - Redemption Reconciliation Report',
  },
  TURNKEY_HISTORICAL_REDEMPTION_RECONCILIATION_RASAPURA_REPORT: {
    functionName:
      'Turnkey Historical - Redemption Reconciliation Report Rasapura',
  },
  TURNKEY_HISTORICAL_TENANT_COMMISSION_REPORT: {
    functionName: 'Turnkey Historical - Tenant Commission Report',
  },
  TURNKEY_HISTORICAL_TENANT_COMMISSION_RASAPURA_REPORT: {
    functionName: 'Turnkey Historical - Tenant Commission Report Rasapura',
  },
  TURNKEY_HISTORICAL_TENANT_HISTORY_REPORT: {
    functionName: 'Turnkey Historical - Tenant History Report',
  },
  TURNKEY_HISTORICAL_TICKET_BY_STATUS_ERROR_REPORT: {
    functionName: 'Turnkey Historical - Ticket By Status Error Report',
  },
  TURNKEY_HISTORICAL_TICKET_BY_STATUS_POSTED_REPORT: {
    functionName: 'Turnkey Historical - Ticket By Status Posted Report',
  },
  TURNKEY_HISTORICAL_USSD_WAIVED_TICKETS_REPORT: {
    functionName: 'Turnkey Historical - USSD Waived Tickets Report',
  },
  EVOUCHER_USAGE_REPORT: {
    functionName: 'Evoucher Usage Report',
  },
  EVOUCHER_TRANSACTION: {
    functionName: 'Evoucher Utilization',
  },
  INVOICE_TRACKING_EVOUCHER: {
    functionName: 'Invoice Tracking Evoucher',
  },
  MEMBER_TRANSACTIONS_REPORT: {
    functionName: 'Member Transactions Report',
  },
  SL_TRANSACTION: {
    functionName: 'SL Transaction',
  },
  ADMIN_SETTINGS: {
    functionName: 'Admin Settings',
  },
  SL_SUMMARY_REPORT: {
    functionName: 'SL Summary Report',
  },
  SL_DETAIL_REPORT: {
    functionName: 'SL Detail Report',
  },
};

export const NO_CHECK_ACCESS_ROUTES = ['/'];

export const USER_ROLES = {
  SUPER_ADMIN: 'SuperAdmin',
  LOYALTY_MARKETING_APPROVER: 'Loyalty Marketing Approver',
  DCS_SUPPORT: 'DCS Support',
  LOYALTY_MARKETING_REQUESTER: 'Loyalty Marketing Requester',
};
